<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template #activator="{ on }">
      <v-text-field
        :value="displayedDate"
        prepend-icon="calendar_today"
        class="vbt-hide-form-control-details"
        hide-details
        readonly
        v-on="on"
        @click="changeShowPickerState"
        @click:prepend="changeShowPickerState"
      />
    </template>

    <div
      :class="{ dark: $vuetify.theme.dark }"
      class="custom-date-range-picker-container"
    >
      <v-date-picker
        :max="maxAllowedDate"
        :value="date"
        color="info"
        type="month"
        class="custom-date-range-picker"
        @change="onPickerChange"
      />
    </div>
  </v-menu>
</template>

<script>
import { formatDisplayDate, getMonthName, getDatesRangeByLastMonth } from '@helpers';

export default {
  name: 'IrInvoiceSchedulerDateRangePicker',

  data() {
    return {
      maxAllowedDate: this.getMaxAllowedDate(),
      displayedDate: '',
      date: '',

      dialog: false,
    };
  },

  mounted() {
    this.setupDates();
    this.emitDates();
  },

  methods: {
    onPickerChange(date) {
      this.setupDates(date);
      this.emitDates();
    },

    setupDates(date = this.$route.query.date) {
      const dateConfig = date ? new Date(date) : new Date();
      const formattedDate = formatDisplayDate(dateConfig.toDateString(), { dateFormat: 'yyyy-MM' });

      this.date = formattedDate;

      if (this.$route.query.date !== formattedDate) {
        this.$router.push({ query: { ...this.$route.query, date: formattedDate } });
      }

      this.displayedDate = `${getMonthName(dateConfig.getMonth())} ${dateConfig.getFullYear()}`;
    },

    getMaxAllowedDate() {
      return formatDisplayDate((new Date()).toDateString(), { dateFormat: 'yyyy-MM' });
    },

    emitDates() {
      this.$emit(
        'change:dates',
        getDatesRangeByLastMonth({ initialDate: this.date }),
      );
    },

    changeShowPickerState() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.custom-date-range-picker {
  border-radius: 4px !important;
  box-shadow: none;
  width: 100%;
}

.custom-date-range-picker-container {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.custom-date-range-picker-container.dark .custom-date-range-picker {
  background-color: #424242;
}

.custom-date-range-picker-container.grey div.v-picker__body {
  background-color: #1e1e1e;
}

.custom-date-range-picker-container.black div.v-picker__body {
  background-color: #121212;
}
</style>
