<template>
  <v-layout column>
    <ir-statement-scheduler-trigger @change:dates="onPickerDateChange" />
    <ir-invoice-scheduler-trigger @change:dates="onPickerDateChange" />
    <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    hide-title-bar
  >
    <v-layout
      align-center
      class="mb-5"
    >
      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-3">
            Account:
          </strong>

          <v-blacker-combobox
            v-model="accountId"
            :items="accountsOptions"
            class="mt-n2"
          />
        </v-layout>
      </v-flex>

      <v-flex
        class="ml-5 mt-n1"
        sm3
      >
        <ir-invoice-scheduler-date-range-picker @change:dates="onPickerDateChange"/>
      </v-flex>

      <v-layout
        justify-space-between
        align-center
        class="mx-15"
      >
        <div
          v-for="colorsLegendConfig in colorsLegend"
          :key="colorsLegendConfig.label"
          style="display: flex; align-items: center;"
        >
          <strong class="mr-2">
            {{ colorsLegendConfig.label }}
          </strong>

          <div
            :class="colorsLegendConfig.color"
            style="width: 10px; height: 10px;"
          />
        </div>
      </v-layout>
    </v-layout>

    <v-layout style="height: 500px;">
      <v-calendar
        v-model="calendarValue"
        :weekdays="calendarConfig.weekdays"
        @change="onCalendarDateChange"
      >
        <template #day="{ date }">
          <v-layout
            v-if="schedule[date]"
            class="fill-height"
          >
            <v-sheet
              style="cursor: pointer;"
              :color="schedule[date].color"
              :title="schedule[date].title"
              width="100%"
              height="100%"
              tile
              @click="goToStatements(date)"
            />
          </v-layout>
        </template>
      </v-calendar>
    </v-layout>
    </vbt-content-box>
    <ir-statement-active />
  </v-layout>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { InvoiceSchedulerApiService } from '../_services/invoiceScheduler.api.service';

import IrInvoiceSchedulerDateRangePicker from '../_components/IrInvoiceSchedulerDateRangePicker.vue';

import IrStatementSchedulerTrigger from '../_components/IrStatementSchedulerTrigger.vue';
import IrInvoiceSchedulerTrigger from '../_components/IrInvoiceSchedulerTrigger.vue';

import IrStatementActive from '../_components/IrStatementActive.vue';

const SCHEDULER_STATE_COLORS = {
  inprogress: 'info',
  success: 'light-blue',
  posted: 'success',
  empty: 'grey darken-2',
  failed: 'error',
};

export default {
  name: 'IrInvoiceScheduler',

  components: {
    IrInvoiceSchedulerDateRangePicker,
    IrStatementActive,
    IrStatementSchedulerTrigger,
    IrInvoiceSchedulerTrigger,
  },

  data() {
    return {
      accountId: this.$route.query.accountId ? Number(this.$route.query.accountId) : '',

      schedule: {},
      calendarConfig: {
        // Mon - Sun
        weekdays: [1, 2, 3, 4, 5, 6, 0],
      },
      calendarValue: '',
      dates: [],

      loadingError: { show: false, text: '' },
      loading: true,
    };
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions']),

    ...mapGetters('enums', ['invoiceSchedulerStatesEnum']),

    colorsLegend() {
      return Object.keys(SCHEDULER_STATE_COLORS).map(key => ({
        color: SCHEDULER_STATE_COLORS[key],
        label: key,
      }));
    },
  },

  watch: {
    accountsOptions: {
      handler(newValue) {
        if (!this.accountId && newValue.length) {
          this.accountId = newValue[0].value;
        }
      },
      deep: true,
    },

    accountId(accountId) {
      if (!accountId || !this.dates.length) {
        return;
      }

      this.getInvoiceSchedule({
        startDate: this.dates[0],
        endDate: this.dates[1],
        accountId,
      });

      if (Number(this.$route.query.accountId) !== accountId) {
        this.$router.push({ query: { ...this.$route.query, accountId } });
      }
    },

    dates: {
      handler(dates) {
        if (dates.length && this.accountId) {
          this.getInvoiceSchedule({
            accountId: this.accountId,
            startDate: dates[0],
            endDate: dates[1],
          });
        }
      },
      deep: true,
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),

    onCalendarDateChange({ start, end }) {
      this.dates = [start.date, `${end.date} 23:59:59`];
    },

    getInvoiceSchedule({ accountId = this.accountId, startDate, endDate }) {
      this.wrapToLoadingFn({
        req: InvoiceSchedulerApiService.getInvoiceSchedule.bind({}, {
          from: startDate,
          to: endDate,
          accountId,
        }),
        loadingFlagName: 'loading',
        onSuccess: r => this.$set(this, 'schedule', this.generateCalendarEventsConfig(r)),
        onReject: e => this.$set(this, 'loadingError', { show: true, text: e }),
      });
    },

    generateCalendarEventsConfig(events) {
      return events.reduce((acc, { state, date, errorMessage }) => {
        const dateKey = formatDisplayDate(date, { dateFormat: 'yyyy-MM-dd' });
        const schedulerState = this.invoiceSchedulerStatesEnum[state];
        const schedulerColor = SCHEDULER_STATE_COLORS[schedulerState.toLowerCase()];
        const invoiceError = schedulerState.toLowerCase() === 'failed';

        acc[dateKey] = {
          invoiceError,
          color: schedulerColor,
          title: invoiceError ? `${schedulerState}. Exception: ${errorMessage}` : schedulerState,
        };

        return acc;
      }, {});
    },

    onPickerDateChange(dates) {
      [this.calendarValue] = dates;
    },

    goToStatements(date) {
      const route = this.$router.resolve({
        name: 'statements',
        query: {
          accountId: this.accountId,
          dates: [date, date],
        },
      });
      window.open(route.href, '_blank');
    },

    wrapToLoadingFn,
  },
};
</script>
