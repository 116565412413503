<template>
  <vbt-content-box
    title="Trigger invoice generation"
  >
    <v-layout align-center>
      <v-flex sm3>
        <vbt-date-picker
          v-model="dates"
          :clearable="false"
          label="Date range"
          confirm
          range
        />
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-3">
            Account:
          </strong>

          <v-blacker-combobox
            v-model="accountId"
            :items="accountsOptions"
            class="mt-n2"
          />
        </v-layout>
      </v-flex>

      <v-flex sm2 ml-5>
        <v-layout align-center>
          <v-checkbox
            v-model="skipLastRunValidation"
            class="vbt-hide-form-control-details mt-2 mr-5"
            label="Skip last run validation"
          />
        </v-layout>
      </v-flex>

      <v-flex sm2>
        <v-layout align-center>
          <v-checkbox
            v-model="skipContinuityValidation"
            class="vbt-hide-form-control-details mt-2 mr-5"
            label="Skip continuity validation"
          />
        </v-layout>
      </v-flex>

      <v-btn
        color="success"
        class="mt-4 ml-10"
        small
        @click="triggerScheduler"
      >
        Trigger
      </v-btn>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { wrapToLoadingFn, getLastWeekRange } from '@helpers';

import { InvoiceSchedulerApiService } from '../_services/invoiceScheduler.api.service';

export default {
  name: 'IrInvoiceSchedulerTrigger',

  data() {
    return {
      dates: getLastWeekRange(),
      accountId: null,
      loading: true,
    };
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions']),
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),
    triggerScheduler() {
      const params = {};

      if (this.dates.length) {
        [params.from, params.to] = this.dates;
      }

      if (this.accountId) {
        params.accountId = this.accountId;
      }

      params.skipLastRunValidation = this.skipLastRunValidation;
      params.skipContinuityValidation = this.skipContinuityValidation;

      this.wrapToLoadingFn({
        req: InvoiceSchedulerApiService.triggerScheduler.bind(null, params),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Invoice generation triggered');
          this.$emit('refresh');
          this.clearForm();
        },
      });
    },
    clearForm() {
      this.dates = getLastWeekRange();
      this.accountId = null;
    },
    wrapToLoadingFn,
  },
};
</script>
